<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-select
            outlined
            flat
            dense
            :items="listType"
            background-color="white"
            v-model="params.type"
            label="Type"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" v-if="params.type === 0">
          <v-autocomplete
            outlined
            flat
            dense
            :items="employees"
            item-text="name"
            item-value="hrId"
            background-color="white"
            v-model="params.employeeId"
            :rules="[v => !!v || 'Employee is required']"
            label="Employee"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" v-if="params.type === 1">
          <v-autocomplete
            outlined
            flat
            dense
            :items="sites"
            item-text="name"
            item-value="name"
            background-color="white"
            v-model="params.site"
            :rules="[v => !!v || 'Site is required']"
            label="Site"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" v-if="params.type === 2">
          <v-autocomplete
            outlined
            flat
            dense
            :items="listPo"
            item-text="purchaseOrderNumber"
            item-value="id"
            background-color="white"
            v-model="params.poId"
            :rules="[v => !!v || 'PO is required']"
            label="PO"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="1">
          <v-btn color="success" @click="print">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="view">
      <iframe
        :src="url"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="700px"
      ></iframe>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import "jspdf-autotable";
import { jsPDF } from "jspdf";

export default {
  name: "report-summary-settlement-kasbon",
  computed: {
    ...mapState("external", ["sites"]),
  },
  data() {
    return {
      params: {
        startDate: moment()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .endOf("month")
          .format("yyyy-MM-DD"),
        employeeId: 0,
        poId: 0,
        site: "",
        type: 0,
      },
      menuStartDate: false,
      menuEndDate: false,
      summary: [],
      employees: [],
      url: "",
      view: false,
      listType: [
        { text: "Employee", value: 0 },
        { text: "Site", value: 1 },
        { text: "PO", value: 2 },
      ],
      listPo: [],
    };
  },
  methods: {
    async getEmployees() {
      await await this.$store
        .dispatch("external/getEmployees")
        .then(response => (this.employees = [{ name: "All", hrId: 0 }, ...response.data]));
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getEmployees(),
        await this.$store.dispatch("external/getSites"),
        this.getPO(),
      ])
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getPO() {
      await this.$store.dispatch("external/getSalePurchaseOrderAll", true).then(response => {
        this.listPo = response.data;
      });
    },
    async print() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/getSummarySettlementKasbon", this.params)
        .then(response => {
          this.summary = response.data;
          this.createPdf();
          this.view = true;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-YY");
    },
    formatDateRange(startDate, endDate) {
      let startDay = moment(startDate).format("DD");
      let endDay = moment(endDate).format("DD");
      let month = moment(startDate).format("MMM");
      if (startDate != null && endDate != null) {
        return startDay + "-" + endDay + "/" + month;
      } else {
        return "";
      }
    },
    totalAmount(items) {
      return items.map(x => x.usedAmount).reduce((x, y) => x + y);
    },
    setNewArray(items) {
      const array = [];
      items.forEach(x => {
        array.push([
          {
            content: this.formatDate(x.kasbonDate),
            styles: { valign: "top", halign: "left" },
          },
          {
            content: x.employeeName,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: `${x.category} ${this.formatDateRange(x.startDate, x.endDate)}`,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: this.formatPrice(x.usedAmount),
            styles: { valign: "top", halign: "right" },
          },
        ]);
      });
      return array;
    },
    createPdf() {
      const doc = new jsPDF();

      doc.setFont("Times", "bold");
      doc.setFontSize(10);
      doc.text("Kas Bon Report", 100, 10);
      doc.text(
        `FROM ${this.formatDate(this.params.startDate)} TO ${this.formatDate(this.params.endDate)}`,
        85,
        15
      );

      let y2 = 20;
      this.summary.forEach(x => {
        y2 += 28;
        doc.autoTable({
          startY: y2,
          margin: { left: 8 },
          theme: "plain",
          body: [
            ...this.setNewArray(x.items),
            [
              {
                colSpan: "3",
                content: "Total",
                styles: { valign: "top", halign: "right" },
              },
              {
                content: this.formatPrice(this.totalAmount(x.items)),
                styles: { valign: "top", halign: "right" },
              },
            ],
          ],
          pageBreak: "avoid",
          styles: {
            valign: "middle",
            lineWidth: 0.1,
            halign: "left",
            lineColor: "black",
            fontSize: 9,
          },
          didDrawPage: () => {
            let y = y2 - 28;
            y += 4;
            doc.text("Assign#:", 8, y);
            doc.text(`${x.kasbonNumber}`, 22, y);

            y += 7;
            doc.setFont("Times", "normal");
            doc.text(x.remark, 8, y, { maxWidth: 190 });

            y += 12;
            doc.setFont("Times", "bold");
            doc.text("Entry Date : ", 8, y);
            doc.text(x.createdAt, 34, y);

            y2 += 50;
            if (y2 >= 240) {
              doc.addPage();
              y2 = 20;
            }
          },
        });
      });

      this.url = doc.output("datauristring");
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
